export type AnalyticsRequestPayload = {
  name: string
  cameraExid: string
} & { [key: string]: any }

export enum AnalyticsEventPageId {
  timeline = "Timeline",
  copilot = "Copilot",
  recordings = "Recordings",
  liveview = "LiveView",
  compare = "Compare",
  bimCompare = "BimCompare",
  sharing = "Sharing",
  timelapse = "Timelapse",
  project = "Project",
  allProjects = "AllProjects",
  tutorials = "Tutorials",
  projectMapView = "PorjectMapView",
  accountMapView = "AccountMapView",
  progressPhotos = "ProgressPhotos",
  progressPhotosUnsubscribe = "ProgressPhotosUnsubscribe",
  videoWall = "VideoWall",
  projectMembers = "ProjectMembers",
  connectors = "Connectors",
  mediaHub = "MediaHub",
  mediaOveriew = "MediaOveriew",
  gateReport = "GateReport",
  bim = "Bim",
  drone = "Drone",
  _360 = "360",
  sitePlanner = "SitePlannerView",
  account = "Account",
  activities = "Activities",
  embeddedLive = "EmbeddedLive",
  embeddedRecordings = "EmbeddedRecordings",
  embeddedBimCompare = "EmbeddedBimCompare",
  projectFeatureInfo = "ProjectFeatureInfo",
  cameraFeatureInfo = "CameraFeatureInfo",
}

export enum AnalyticsEvent {
  // Global events
  pageView = "PageView",

  // Layout events
  layoutToggleRightSidebar = "Layout-ToggleRightSidebar",
  layoutResizeRightSidebar = "Layout-ResizeRightSidebar",
  layoutThemeMode = "Layout-ThemeMode",

  // Features toggle events
  xray = "Xray-Toggle",
  weather = "Weather-Toggle",
  editTool = "EditTool-Toggle",
  comment = "Comment-Toggle",
  compare = "Compare-Toggle",
  copilot = "Copilot-Toggle",

  // Player events
  playerSelectCamera = "Player-SelectCamera",
  playerDownload = "Player-Download",
  playerToggleFullscreen = "Player-ToggleFullscreen",
  playerEnableLive = "Player-EnableLive",
  playerTogglePlayback = "Player-TogglePlayback",
  playerSelectSpeed = "Player-SelectSpeed",
  playerSelectQuality = "Player-SelectQuality",
  playerJumptoNextTicks = "Player-JumptoNextTicks",
  playerJumptoPreviousTicks = "Player-JumptoPreviousTicks",
  playerSwitchMode = "Player-SwitchMode",
  playerZoomIn = "Player-ZoomIn",
  playerZoomOut = "Player-ZoomOut",

  // Timeline actions
  timemlineSelectMilestoneItem = "Timeline-SelectMilestoneItem",
  timelineClickEvent = "Timeline-ClickEvent",
  timelineSelectTimestamp = "Timeline-SelectTimestamp",

  // Copilot
  copilotSendMessage = "Copilot-SendMessage",
  copilotClickTimestamp = "Copilot-ClickTimestamp",
  copilotRegenerate = "Copilot-Regenerate",
  copilotError = "Copilot-Error",
  copilotNavigation = "Copilot-Navigation",
  copilotToggle = "Copilot-Toggle",

  // Timeline groups visibitlity events
  groupsVisibilityMobileCapture = "GroupsVisibility-MobileCapture",
  groupsVisibilityAnpr = "GroupsVisibility-Anpr",
  groupsVisibilityComments = "GroupsVisibility-Comments",
  groupsVisibilityMediaHub = "GroupsVisibility-MediaHub",
  groupsVisibilityBimMilestines = "GroupsVisibility-BimMilestines",
  groupsVisibilityDroneFlights = "GroupsVisibility-DroneFlights",
  groupsVisibility360Walks = "GroupsVisibility-360Walks",
  groupsVisibilitySafetyReport = "GroupsVisibility-SafetyReport",
  groupsVisibilityConstructionReport = "GroupsVisibility-ConstructionReport",
  groupsVisibilitySiteActivity = "GroupsVisibility-SiteActivity",
  groupsVisibilityExNvrVideoRecordings = "GroupsVisibility-ExNvrVideoRecordings",

  // Comments events
  commentsPlaceMarker = "Comments-PlaceMarker",
  commentsSave = "Comments-Save",
  commentsCancelSave = "Comments-CancelSave",
  commentsRemove = "Comments-Remove",
  commentsCancelRemove = "Comments-CancelRemove",
  commentsArchive = "Comments-Archive",
  commentsCancelArchive = "Comments-CancelArchive",
  commentsSelect = "Comments-Select",

  // Xray events
  xrayMove = "Xray-Move",
  xrayMoveBeforeMarker = "Xray-MoveBeforeMarker",
  xrayMoveAfterMarker = "Xray-MoveAfterMarker",
  xrayToggleExportDialog = "Xray-ToggleExportDialog",
  xrayDownload = "Xray-Download",
  xraySaveToMediaHub = "Xray-SaveToMediaHub",
  xarySelectDate = "Xray-SelectDate",
  xarySelectLatestDate = "Xray-SelectLatestDate",
  xarySelectOldestDate = "Xray-SelectOldestDate",
  xraySendToConnector = "Xray-SendToConnector",
  // Weather events
  weatherSelectProvider = "Weather-SelectProvider",
  weatherSelectDate = "Weather-SelectDate",
  weatherGoBackDate = "Weather-GoBackDate",
  weatherGoForwardDate = "Weather-GoForwardDate",
  weatherSelectTempScale = "Weather-SelectTempScale",
  weatherSelectLegend = "Weather-SelectLegend",

  // Compare events
  compareSlide = "Compare-Slide",
  compareMoveBeforeMarker = "Compare-MoveBeforeMarker",
  compareMoveAfterMarker = "Compare-MoveAfterMarker",
  compareSelectBeforeDate = "Compare-SelectBeforeDate",
  compareSelectBeforeLatestDate = "Compare-SelectBeforeLatestDate",
  compareSelectBeforeOldestDate = "Compare-SelectBeforeOldestDate",
  compareSelectAfterDate = "Compare-SelectAfterDate",
  compareSelectAfterOldestDate = "Compare-SelectAfterOldestDate",
  compareSelectAfterLatestDate = "Compare-SelectAfterLatestDate",
  compareToggleExportDialog = "Compare-ToggleExportDialog",
  compareSaveToMediaHub = "Compare-SaveToMediaHub",
  compareGoToMediaHub = "Compare-GoToMediaHub",
  compareDownloadAs = "Compare-DownloadAs",
  compareCopyDownloadFileUrl = "Compare-CopyDownloadFileUrl",

  // Bim Compare events
  bimCompareSlide = "BimCompare-Slide",
  bimCompareSelectCameraDate = "BimCompare-SelectCameraDate",
  bimCompareSelectCameraLatestDate = "BimCompare-SelectCameraLatestDate",
  bimCompareSelectCameraOldestDate = "BimCompare-SelectCameraOldestDate",
  bimCompareSelectBimDate = "BimCompare-SelectBimDate",
  bimCompareSelectBimOldestDate = "BimCompare-SelectBimOldestDate",
  bimCompareSelectBimLatestDate = "BimCompare-SelectBimLatestDate",
  bimCompareSelectModel = "BimCompare-SelectModel",
  bimCompareChangeTransparency = "BimCompare-ChangeTransparency",
  bimCompareSelectTimelineDate = "BimCompare-SelectTimelineDate",
  bimCompareToggleExportDialog = "BimCompare-ToggleExportDialog",
  bimCompareSaveToMediaHub = "BimCompare-SaveToMediaHub",
  bimCompareGoToMediaHub = "BimCompare-GoToMediaHub",
  bimCompareDownloadAs = "BimCompare-DownloadAs",
  bimCompareCopyDownloadFileUrl = "BimCompare-CopyDownloadFileUrl",

  // Edit tool events
  editToolSelectTool = "EditTool-SelectTool",
  editToolUndo = "EditTool-Undo",
  editToolRedo = "EditTool-Redo",
  editToolSelectColor = "EditTool-SelectColor",
  editToolApplyCrop = "EditTool-ApplyCrop",
  editToolCancelCrop = "EditTool-CancelCrop",
  editToolCancelSend = "EditTool-CancelSend",
  editToolOpenEditName = "EditTool-OpenEditName",
  editToolCancelEditName = "EditTool-CancelEditName",
  editToolSaveEditedName = "EditTool-SaveEditedName",
  editToolSaveToMediaHub = "EditTool-SaveToMediaHub",
  editToolDownloadAs = "EditTool-DownloadAs",
  editToolSendToConnector = "EditTool-SendToConnector",

  // Ai Tools events
  aiToolSelectObjectDetection = "AITool-SelectObjectDetection",
  aiToolSelectAskChatGpt = "AITool-SelectAskChatGpt",
  aiToolSelectSegmentation = "AITool-SelectSegmentation",
  aiToolSelectDepthAnalysis = "AITool-SelectDepthAnalysis",

  // Recordings events
  recordingsSelectDate = "Recordings-SelectDate",
  recordingsSelectLatestDate = "Recordings-SelectLatestDate",
  recordingsSelectOldestDate = "Recordings-SelectOldestDate",
  recordingsClickSupportLink = "Recordings-ClickSupportLink",

  // Sharing events
  sharingToggleAddUserDialog = "Sharing-ToggleAddUserDialog",
  sharingAddUser = "Sharing-AddUser",
  sharingToggleDeleteUserDialog = "Sharing-ToggleDeleteUserDialog",
  sharingDeleteUser = "Sharing-DeleteUser",
  sharingSaveVisibilityOptions = "Sharing-SaveVisibilityOptions",
  sharingCopyLink = "Sharing-CopyLink",
  sharingResendInvite = "Sharing-ResendInvite",
  sharingTransferOwnership = "Sharing-TransferOwnership",
  sharingSortBy = "Sharing-SortBy",
  sharingChangeAccessRight = "Sharing-ChangeAccessRight",

  // Timelapse Creator events
  timelapseToggleHelpDialog = "Timelapse-ToggleHelpDialog",
  timelapseSelectCustomPeriod = "Timelapse-SelectCustomPeriod",
  timelapseToggleCustomScheduleDialog = "Timelapse-ToggleCustomScheduleDialog",
  timelapseSaveCustomSchedule = "Timelapse-SaveCustomSchedule",
  timelapseToggleExportDialog = "Timelapse-ToggleExportDialog",
  timelapseExportSelectStep = "Timelapse-SelectStep",
  timelapseSelectOption = "Timelapse-SelectOption",
  timelapseSaveToMediaHub = "Timelapse-SaveToMediaHub",
  timelapseGoToMediaHub = "Timelapse-GoToMediaHub",
  timelapseToggleUploadLogoDialog = "Timelapse-ToggleUploadLogoDialog",
  timelapseUploadLogo = "Timelapse-UploadLogo",

  // Progress Photos events
  progressPhotoToggleCreateDialog = "ProgressPhoto-ToggleCreateDialog",
  progressPhotoToggleEditDialog = "ProgressPhoto-ToggleEditDialog",
  progressPhotoSelectProvider = "ProgressPhoto-SelectProvider",
  progressPhotoSelectMember = "ProgressPhoto-SelectMember",
  progressPhotoSelectCameras = "ProgressPhoto-SelectCameras",
  progressPhotoSelectDays = "ProgressPhoto-SelectDays",
  progressPhotoSelectTime = "ProgressPhoto-SelectTime",
  progressPhotoSelectTimezone = "ProgressPhoto-SelectTimezone",
  progressPhotoCreate = "ProgressPhoto-Create",
  progressPhotoEdit = "ProgressPhoto-Edit",
  progressPhotoPause = "ProgressPhoto-Pause",
  progressPhotoResume = "ProgressPhoto-Resume",
  progressPhotoDelete = "ProgressPhoto-Delete",
  progressPhotoUnsubscribeUser = "ProgressPhoto-UnsubscribeUser",

  // Map View events
  mapViewToggleCameraMapInfoTooltip = "MapView-ToggleCameraMapInfoTooltip",
  mapViewClickCameraLink = "MapView-ClickCameraLink",

  // Video Wall events
  videoWallToggleFullscreen = "VideoWall-ToggleFullscreen",
  videoWallToggleCameraFullscreen = "VideoWall-ToggleCameraFullscreen",
  videoWallToggleConfigDialog = "VideoWall-ToggleConfigDialog",
  videoWallToggleCameraVisibility = "VideoWall-ToggleCameraVisibility",
  videoWallPositionCameraOnWall = "VideoWall-PositionCameraOnWall",
  videoWallSaveConfig = "VideoWall-SaveConfig",

  // Project Members events
  projectMembersToggleAddDialog = "ProjectMembers-ToggleAddDialog",
  projectMembersAddMember = "ProjectMembers-AddMember",
  projectMembersShareCamera = "ProjectMembers-ShareCamera",
  projectMembersEditCameraAccess = "ProjectMembers-EditCameraAccess",
  projectMembersDeleteCameraAccess = "ProjectMembers-DeleteCameraAccess",

  // Connectors events
  connectorsConnectToProcore = "Connectors-ConnectToProcore",
  connectorsToggleAconexDialog = "Connectors-ToggleAconexDialog",
  connectorsConnectToAconex = "Connectors-ConnectToAconex",
  connectorsConnectToAutodesk = "Connectors-ConnectToAutodesk",
  connectorsToggleVoyageControlDialog = "Connectors-ToggleVoyageControlDialog",
  connectorsConnectToVoyageControl = "Connectors-ConnectToVoyageControl",
  connectorsToggleConnectorPromptDialog = "Connectors-ToggleConnectorPromptDialog",

  // Media Hub events
  mediaHubSelectFilter = "MediaHub-SelectFilter",
  mediaHubApplyFilters = "MediaHub-ApplyFilters",
  mediaHubToggleAddMediaUrlDialog = "MediaHub-ToggleAddMediaUrlDialog",
  mediaHubToggleUploadFileDialog = "MediaHub-ToggleUploadFileDialog",
  mediaHubSaveMediaUrl = "MediaHub-SaveMediaUrl",
  mediaHubUploadFile = "MediaHub-UploadFile",
  mediaHubSelectPage = "MediaHub-SelectPage",
  mediaHubGoToNextPage = "MediaHub-GoToNextPage",
  mediaHubGoToPreviousPage = "MediaHub-GoToPreviousPage",

  // Media Hub Item events
  mediaDelete = "Media-Delete",
  mediaToggleTitleEditing = "Media-ToggleTitleEditing",
  mediaSaveTitle = "Media-SaveTitle",
  mediaDownloadAs = "Media-DownloadAs",
  mediaTogglePublicVisibility = "Media-TogglePublicVisibility",
  mediaShareToPlatform = "Media-ShareToPlatform",
  mediaCopyMediaLink = "Media-CopyMediaLink",
  mediaGoToMediaHub = "Media-GoBackToMediaHub",

  // Features Info events
  infoGateReport = "GateReport",
  infoBim = "Bim",
  infoDrone = "Drone",
  info360 = "360",
  infoBimCompare = "BimCompare",

  // Gate report
  gateReportExportPdf = "GateReport-ExportPdf",
  gateReportExportCsv = "GateReport-ExportCsv",

  // TODO: The events bellow are old events, should be updated!
  // Recordings
  createClip = "create_clip",

  // Gate Report
  clickGateReportOpenInRecording = "click_gate_report_open_in_recording",
  filterGateReportCameras = "filter_gate_report_cameras",
  filterGateReportVehicleTypes = "filter_gate_report_vehicle_types",
  filterGateReportEntryExit = "filter_gate_report_entry_exit",
  clickGateReportTimelineDate = "click_gate_report_timeline_date",
  filterGateReportPlateNumber = "filter_gate_report_plate_number",
}
