import { AdminCamera, Camera } from "@evercam/shared/types/camera"
import type { EntityByExid, Exid } from "./index"

export type ProjectExid = Exid

export type ProjectsByExid = EntityByExid<Project>

export type Project = {
  id: string | number
  exid: ProjectExid
  name: string
  status?: ProjectStatus
  insertedAt?: string
  startedAt?: string
  timezone?: string
  zohoId?: string
  cameras?: Array<Camera | AdminCamera>
  featureFlags: Array<ProjectFeatureFlag>
  owner?: { email?: string }
}

export enum ProjectStatus {
  ToStart = "To Start",
  InProgress = "In Progress",
  Completed = "Completed",
  notAplicable = "Not Applicable",
}

export type ProjectResponsePayload = {
  data?: Project[]
}

export type Logo = {
  name: string
  url: string
}

export type ProjectLogoResponsePayload = Logo[]

export enum ProjectFeatureFlag {
  ANPR_LEGACY = "anpr_legacy",
  DRONE_VIEW = "drone_view",
  BIM = "4d_bim",
  BIM_ITWIN = "4d_bim_itwin",
  BIM_FORGE = "4d_bim_forge",
  _360View = "360_view",
  NDA_MC = "NDA/MC",
  GATE_REPORT = "gate_report",
  GATE_REPORT_AUTO_VERIFY = "auto_publish",
  GATE_REPORT_MOTION_DETECTION = "motion_detection",
  FULL_EMPTY = "full_empty",
  MediaHubSharing = "archives_sharing",
  GATE_REPORT_ANPR_BASED = "anpr_sourced",
}
