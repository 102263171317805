import type {
  Timestamp,
  CameraExid,
  PaginationParams,
  PaginatedItems,
  DateTime,
  DateTimeSpaceSeparated,
  ProjectExid,
  TimelinePrecision,
  AnprDirection,
} from "@evercam/shared/types"
import type {
  Date_YYYY,
  Date_YYYY_MM,
  Date_YYYY_MM_DD,
  Date_HH,
} from "@evercam/shared/types/time"

export enum GateReportDetectionType {
  auto = "auto",
  manual = "manual",
  cloud = "cloud",
  edge = "edge",
}

export enum GateReportEventType {
  arrived = "arrived",
  left = "left",
}

export enum DetectionLabel {
  articulatedDumper = "articulated-dumper",
  backhoeLoader = "backhoe-loader",
  compactExcavator = "compact-excavator",
  concretePump = "concrete-pump",
  dumper = "dumper",
  excavator = "excavator",
  flatbedSemiTrailer = "flatbed-semi-trailer",
  industrialTruck = "industrial-truck",
  loader = "loader",
  mewps = "mewps",
  mobileCrane = "mobile-crane",
  mobileCrusher = "mobile-crusher",
  otherTruck = "other-truck",
  pileDrillingRig = "pile-drilling-rig",
  roadVehicle = "road-vehicle",
  roller = "roller",
  semiTrailer = "semi-trailer",
  skidSteerLoader = "skid-steer-loader",
  skipTruck = "skip-truck",
  smallTruck = "small-truck",
  tankTruck = "tank-truck",
  teleHandler = "tele-handler",
  tippingTruck = "tipping-truck",
  towerCrane = "tower-crane",
  towerMountedConcreteMix = "tower-mounted-concrete-mix",
  tractorDozer = "tractor-dozer",
  truckHead = "truck-head",
  truckMixer = "truck-mixer",
  truckMountedMobileCrane = "truck-mounted-mobile-crane",
  unknown = "unknown",
  worker = "worker",
}

export enum GateReportVehicleType {
  concretePump = DetectionLabel.concretePump,
  flatbedSemiTrailer = DetectionLabel.flatbedSemiTrailer,
  otherTruck = DetectionLabel.otherTruck,
  roadVehicle = DetectionLabel.roadVehicle,
  semiTrailer = DetectionLabel.semiTrailer,
  smallTruck = DetectionLabel.smallTruck,
  tankTruck = DetectionLabel.tankTruck,
  tippingTruck = DetectionLabel.tippingTruck,
  truckHead = DetectionLabel.truckHead,
  truckMixer = DetectionLabel.truckMixer,
  unknown = DetectionLabel.unknown,
}

export type GateReportVehicleTypeItem = {
  name: string
  id: GateReportVehicleType
}

export type GateReportEvent = {
  id: number
  vehicleTypes: GateReportVehicleType
  exid: string
  cameraExid?: string
  boundingBox: BoundingBox
  detectionType: GateReportDetectionType
  edited: boolean
  eventTime: string
  timestamp?: string
  eventType: GateReportEventType
  isPublic: boolean
  roiId: string
  truckType: GateReportVehicleType
  thumbnailUrl?: string
}

export type GateReportArrivedEvent = GateReportEvent & {
  eventType: GateReportEventType.arrived
}

export type GateReportLeftEvent = GateReportEvent & {
  eventType: GateReportEventType.left
}

export type GateReportVerifiedDay = {
  id: number
  day: Date_YYYY_MM_DD
  isMatched: boolean
  insertedAt: DateTime
  updatedAt: DateTime
  verifiedBy: string
  cameraExid: CameraExid
  projectExid: ProjectExid
}

export type BoundingBox = {
  xmax: number
  xmin: number
  ymax: number
  ymin: number
}

export type EventsCountsByVehicleType = Record<GateReportVehicleType, number>

export type EventsCount = {
  in: number
  out: number
  source: GateReportDataSource
}

export type YearlyEventCount = EventsCount & {
  year: Date_YYYY
}

export type MonthlyEventCount = EventsCount & {
  month: Date_YYYY_MM
}

export type DailyEventCount = EventsCount & {
  day: Date_YYYY_MM_DD
}

export type HourlyEventCount = {
  hour: Date_HH
  count: number
}

export type EventCountRequestPayload = {
  vehicleTypes?: GateReportVehicleType[]
  isPublic?: boolean
  camerasExid?: string[]
}

export type EventCountResponsePayload = {
  days?: DailyEventCount[]
  months?: MonthlyEventCount[]
  years?: YearlyEventCount[]
}

export type ProcessedDaysRequestPayload = {
  apiId?: string
  apiKey?: string
  isPublic?: Boolean
}

export type EventsRequestPayload = {
  apiId?: string
  apiKey?: string
  camerasExid?: CameraExid[]
  eventType?: GateReportEventType
  fromDate?: DateTime
  isPublic?: boolean
  toDate?: DateTime
  truckTypes?: GateReportVehicleType[]
} & PaginationParams

export type GateReportEventsDetailedCountsPayload = {
  endDate: DateTime
  startDate: DateTime
  precision: TimelinePrecision
  isPublic?: boolean
  cameras?: CameraExid[]
  vehicleTypes?: GateReportVehicleType[]
  eventType?: GateReportEventType
}

export type GateReportDetailedEventsCount = {
  date: string
  counts: Record<GateReportVehicleType, number>
}

export type EventsResponsePayload = PaginatedItems<GateReportEvent>

export enum GateReportDataSource {
  Anpr = "anpr",
  Events = "events",
}

export type GateReportResponsePayload = {
  source: GateReportDataSource
  results: PaginatedItems<GateReportEvent>
}

export enum GateReportEventDirection {
  In = "in",
  Out = "out",
  Unknown = "unknown",
}

export type GatReportRequestPayload = {
  camerasExid?: CameraExid[]
  eventType?: GateReportEventType
  fromDate?: DateTime
  isPublic?: boolean
  toDate?: DateTime
  vehicleTypes?: GateReportVehicleType[]
} & PaginationParams

export enum GateReportExportType {
  pdf = "pdf",
  csv = "csv",
}

export type EventsExportRequestParameters = {
  vehicleTypes: GateReportVehicleType[]
  fileType: GateReportExportType
  fromDate: DateTimeSpaceSeparated
  toDate: DateTimeSpaceSeparated
  camerasExid: CameraExid[]
  eventType: GateReportEventType
}

export type AnprBasedGateReportExportRequestParams = {
  vehicleTypes?: GateReportVehicleType[]
  fileType?: GateReportExportType
  fromDate?: DateTimeSpaceSeparated
  toDate?: DateTimeSpaceSeparated
  camerasExid?: CameraExid[]
  direction?: AnprDirection
}

type X1 = number
type Y1 = number
type X2 = number
type Y2 = number

export enum GateReportROIShapeType {
  rectangle = "rectangle",
  direction = "direction",
  line = "line",
}

export type GateReportROIShape = {
  coordinates: [[X1, Y1], [X2, Y2]]
  type: GateReportROIShapeType
}

export type GateReportROI = {
  cameraex?: string
  fromDate?: string
  directionFilter?: string
  id?: number
  insertedAt?: Timestamp
  isactive?: boolean
  name?: string
  roiType?: string
  updatedAt?: Timestamp
  shapes?: GateReportROIShape[]
}

export enum GateReportProcessingStatus {
  processed = "processed",
  unprocessed = "unprocessed",
}
