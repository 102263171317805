import { ApiCredentials } from "@evercam/shared/types/credentials"
import { CancelToken } from "axios"

export type Snapshot = {
  createdAt: string
  data: string
  status?: string
}

export type NearestSnapshotRequestPayload = {
  apiKey?: string
  apiId?: string
  cancelToken?: object
}

export type AvailableDaysRequestPayload = {
  cameraId: string
  year: string
  month: string
  payload?: ApiCredentials
}
export type AvailableDaysResponsePayload = {
  days: Array<number>
}

export type AvailableHoursRequestPayload = {
  cameraId: string
  year: string
  month: string
  day: string
  payload?: ApiCredentials
  cancelToken?: CancelToken
}
export type AvailableHoursResponsePayload = {
  hours: Array<number>
}

export type NearestSnapshotResponsePayload = {
  snapshots: Array<{
    createdAt: string
    data: string
    notes?: string
  }>
}

export type SnapshotRangeRequestPayload = {
  from: string
  to: string
  limit?: number
  page?: number
  schedule?: boolean
  count?: number
}
export type SnapshotInstance = {
  createdAt: string
  notes?: string
  data?: string
}
export type SnapshotRangeResponsePayload = {
  snapshots: Array<SnapshotInstance>
}

export enum BrainTool {
  DETECTIONS = "detections",
  CHATGPT = "chatgpt",
  SEGMENTATION = "sam",
  DEPTH_ANALYSIS = "depthAnalysis",
}

export enum CloudRecordingFrequency {
  EVERY_SECOND = 60,
  EVERY_2_SECONDS = 30,
  EVERY_5_SECONDS = 12,
  EVERY_10_SECONDS = 6,
  EVERY_15_SECONDS = 4,
  EVERY_30_SECONDS = 2,
  EVERY_60_SECONDS = 1,
  EVERY_5_MINUTES = 5,
  EVERY_10_MINUTES = 10,
}
